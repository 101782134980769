<template>
    <div class="About">
        <p class="justify">
            苏州复数智能科技有限公司，是苏州市相城区高层次人才创业领军项目，专注数字孪生领域。公司拥有多位海内外资深技术专家，基于多年底层物理算法开发经验，以材料仿真模拟方向为切入口，已自主研发出FemTCAD、SHINE、SigSimu等软件。我们将数字可视化与科学仿真模拟技术进一步结合，针对具体的产业技术问题，进行全方位、多尺度的整合，致力于打造高度定制化的产业、科研模拟平台和数字孪生应用的全面覆盖。
        </p>
        <p class="justify">
            我们目前的业务，主要包括为科研工作者提供科研成果可视化展示，实验仿真和高性能计算资源解决方案 ；为产业客户提供材料设计与器件仿真模拟、产线数字孪生定制化解决方案。
        </p>
        <!-- <div class="about-img" /> -->
    </div>
</template>

<script>
export default {
  name: 'About',
  data () {
        return {

        }
      },
      methods: {

      },
      created () {
        this.$emit('contentTitle', '公司简介')
        this.$emit('isShowA', false)
      }
}
</script>
<style lang="less" scoped>
.About{
  margin-bottom:6.25rem;
  p{
    text-indent: 2em;
    font-size: 1.125rem;
    color: rgba(0,0,0,.65);
  }
  .about-img{
    width: 300px;
    height: 200px;
    background: #99a9bf;
    border-radius: 9px;
  }
}
</style>
